// Defining all type actions happenning for user

const UserActionTypes = {
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SET_USER_TO_NULL: "SET_USER_TO_NULL",
  UPDATE_USER_ADMINCHATS: "UPDATE_USER_ADMINCHATS",
  UPDATE_LOCAL_USER: "UPDATE_LOCAL_USER",
};

export default UserActionTypes;
