// Defining chatrooms action types

const ChatRoomActionTypes = {
  FETCH_CHATROOMS_START: "FETCH_CHATROOMS_START",
  FETCH_CHATROOMS_SUCCESS: "FETCH_CHATROOMS_SUCCESS",
  FETCH_CHATROOMS_FAILURE: "FETCH_CHATROOMS_FAILURE",
  ADD_CHATROOM: "ADD_CHATROOM",
  REMOVE_CHATROOM: "REMOVE_CHATROOM",
  CHATROOM_MODIFICATION_SUCCESS: "CHATROOM_MODIFICATION_SUCCESS",
  CHATROOM_MODIFICATION_FAILURE: "CHATROOM_MODIFICATION_FAILURE",
  CHANGE_ACTIVE_CHATROOM: "CHANGE_ACTIVE_CHATROOM",
  SET_ACTIVE_CHATROOM_MESSAGES: "SET_ACTIVE_CHATROOM_MESSAGES",
};

export default ChatRoomActionTypes;
